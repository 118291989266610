import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Story = ({ location }) => {

  const addLinks = (node, index) => {
    if (node.type === 'tag' && node.name === 'a' && node.children[0].data === 'Lunar Explorer') {
      // console.log(index, node)
      return <Link to="/lunar-explorer">{node.children[0].data}</Link>
    } 
  }
  return (
      <StaticQuery
        query={graphql`
        query {
          wpgraphql {
            page(id: "/our-story/", idType: URI) {
              content
              title
              slug
              uri
            }
          }
        }
        `}
        render={data => 
            <Layout location={location}>
              <SEO
                keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
                title="Our Story"
              />
              <section id="ourStory" className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
              <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
              <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content, { transform: addLinks }) }</div>;
              <div>
            </div>
            </section>
            </Layout>
          }
      />
  )
}

Story.propTypes = {
  location: PropTypes.object
};

export default Story;
